import { FormOutlined } from "@ant-design/icons";
import PageTitle from "@component/PageTitle";
import { RootState } from "@redux/rootReducer";
import { fetchSlotDetail, updateSlot } from "@redux/slotSlice";
import { showRequiredFieldsWarning } from "@utils/commonMessage";
import { Button, DatePicker, Form, Input, Tabs } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import moment, { Moment } from "moment";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import { SlotPatternList, SlotPatternValue } from "../constant";
import ContentTab from "./ContentTab";
import TargetTab from "./TargetTab";

const Wrapper = styled.div`
  padding: 20px 41px 56px 24px;
  & .ant-form-item-explain {
    min-height: 0;
  }
  & .ant-form-item {
    margin-bottom: 0;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;
const RowTitle = styled.div`
  align-self: flex-start;
  line-height: 32px;
  margin-right: 10px;
  font-size: 16px;
`;
const RequiredIcon = styled.span`
  color: red;
`;
const RowContent = styled.div<{ width?: number }>`
  width: ${({ width }) => (width ? `${width}px` : "auto")};
`;
const RowText = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const EditIcon = styled(FormOutlined)`
  margin-left: 4px;
`;
const Footer = styled.div`
  position: fixed;
  right: 25px;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 13px 12px;
  align-items: center;
  justify-content: flex-end;
  background-color: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
`;
const CustomDatePicker = styled(DatePicker)<{ showTime?: any }>`
  margin-right: 4px;
`;
const BodyContainer = styled.div`
  padding: 14px 28px 59px 24px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
`;

interface SlotFormType {
  name: string;
  title: string;
  slotType: number;
  slotPattern: number;
  startAt: Moment;
  endAt: Moment;
}

const EditSlot: FC = () => {
  const dispatch = useAppDispatch();
  const { id: slotId } = useParams();
  const [form] = Form.useForm<SlotFormType>();
  const slotDetail = useSelector((state: RootState) => state.slot.slotDetail);

  const [editSlotName, setEditSlotName] = useState<boolean>(false);

  const { slotPattern, name } = slotDetail;
  const hideTitleInput =
    slotPattern === SlotPatternValue.CAROUSEL_PATTERN ||
    slotPattern === SlotPatternValue.FLAT_CAROUSEL_PATTERN ||
    slotPattern === SlotPatternValue.SINGLE_IMAGE_PATTERN ||
    slotPattern === SlotPatternValue.FILM_PATTERN ||
    slotPattern === SlotPatternValue.ANNOUNCEMENT_PATTERN ||
    slotPattern === SlotPatternValue.HTML_TEXT_PRODUCT_PATTERN ||
    slotPattern === SlotPatternValue.SEARCH_RECOMMEND_PATTERN;

  const includeSpecialSlotPattern = useMemo(() => {
    const pattern = SlotPatternList.find((ptn) => ptn.id === slotPattern)!;
    return (
      pattern.id === SlotPatternValue.VIP_ONLY_PATTERN ||
      pattern.id === SlotPatternValue.VIP_RECOMMENDATION_PATTERN ||
      pattern.id === SlotPatternValue.ANNOUNCEMENT_PATTERN ||
      pattern.id === SlotPatternValue.SEARCH_RECOMMEND_PATTERN
    );
  }, [slotPattern]);

  const patternDisplayName = useMemo(() => {
    const pattern = SlotPatternList.find((ptn) => ptn.id === slotPattern)!;
    if (includeSpecialSlotPattern) return null;
    return <RowTitle>{`${pattern.value}:`}</RowTitle>;
  }, [includeSpecialSlotPattern, slotPattern]);

  useEffect(() => {
    dispatch(fetchSlotDetail(Number(slotId)));
  }, [dispatch, slotId]);

  useEffect(() => {
    const startTime = slotDetail.startAt === null ? undefined : slotDetail.startAt;
    const endTime = slotDetail.endAt === null ? undefined : slotDetail.endAt;

    const formValue = {
      name: slotDetail.name,
      slotPattern: slotDetail.slotPattern,
      slotType: slotDetail.slotType,
      startAt: moment(startTime),
      endAt: moment(endTime),
      title: slotDetail.title,
    };
    form.setFieldsValue(formValue);
    setEditSlotName(false);
  }, [form, slotDetail]);

  const handleOnSubmit = () => {
    const values = form.getFieldsValue();

    const params = {
      name: values.name,
      title: values.title,
      slotPattern: values.slotPattern,
      slotType: values.slotType,
      startAt: moment(values.startAt).format("YYYY-MM-DD HH:mm:ss"),
      endAt: moment(values.endAt).format("YYYY-MM-DD HH:mm:ss"),
    };
    dispatch(
      updateSlot({
        id: Number(slotId),
        params,
      }),
    );
  };

  return (
    <Wrapper>
      <PageTitle title={`版位管理 - ${slotDetail.name}`} />
      <Form form={form} onFinish={handleOnSubmit} onFinishFailed={showRequiredFieldsWarning}>
        <Row>
          {patternDisplayName}
          <RowContent>
            <Form.Item name="name" rules={[{ required: true, message: "" }]}>
              {editSlotName ? (
                <Input />
              ) : (
                <RowText>
                  {name}
                  {slotPattern !== SlotPatternValue.VIP_ONLY_PATTERN &&
                    slotPattern !== SlotPatternValue.VIP_RECOMMENDATION_PATTERN &&
                    slotPattern !== SlotPatternValue.ANNOUNCEMENT_PATTERN &&
                    slotPattern !== SlotPatternValue.SEARCH_RECOMMEND_PATTERN && (
                      <EditIcon onClick={() => setEditSlotName(true)} />
                    )}
                </RowText>
              )}
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            版位上架時間
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <Form.Item
            name="startAt"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <CustomDatePicker
              locale={locale}
              showTime={{
                hideDisabledOptions: true,
                defaultValue: moment("00:00:00", "HH:mm:ss"),
              }}
            />
          </Form.Item>
          <Form.Item
            name="endAt"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <CustomDatePicker
              locale={locale}
              showTime={{
                hideDisabledOptions: true,
                defaultValue: moment("23:59:59", "HH:mm:ss"),
              }}
            />
          </Form.Item>
        </Row>
        <BodyContainer>
          {!hideTitleInput && (
            <Row>
              <RowTitle>前台顯示標題</RowTitle>
              <RowContent width={400}>
                <Form.Item name="title">
                  <Input placeholder="文案：建議輸入 20 字元" />
                </Form.Item>
              </RowContent>
            </Row>
          )}
          <Tabs defaultActiveKey="content" type="card">
            <Tabs.TabPane tab="版位內容" key="content">
              <ContentTab />
            </Tabs.TabPane>
            {!includeSpecialSlotPattern && (
              <Tabs.TabPane tab="放置頁面" key="targetPage">
                <TargetTab />
              </Tabs.TabPane>
            )}
          </Tabs>
        </BodyContainer>
        <Footer>
          <Button type="primary" htmlType="submit">
            儲存此分頁
          </Button>
        </Footer>
      </Form>
    </Wrapper>
  );
};

export default EditSlot;
