import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Product, ProductStockInfo } from "@api/throughShipment/onlineOrderApi";
import PopupBackground from "@component/PopupBackground";
import { Button, Form, Input, Row, Select, Spin } from "antd";
import moment from "moment";
import fontStyle from "src/styles/fontStyle";
import { useDispatch, useSelector } from "react-redux";
import {
  onlineOrderState,
  fetchCreateProduct,
  fetchUpdateProduct,
  fetchProductDetailBySku,
  fetchProductStockBySku,
} from "@redux/onlineOrderSlice";
import { useParams } from "react-router-dom";
import storageTypeOptions from "@constant/StorageType";

const Wrapper = styled(Form)`
  position: relative;
  min-width: 650px;
  padding: 30px;
  background: ${({ theme }) => theme.colorNeutral100};
  overflow: auto;
  .ant-form-item-label {
    max-width: 131px;
    min-width: 131px;
    text-align: left;
  }
  .ant-form-item-required::before {
    order: 1;
  }
  .ant-form-item-required::after {
    display: none;
  }
  .ant-input {
    width: 230px;
  }
  .ant-form-item {
    margin-bottom: 5px;
  }
  .ant-select {
    width: 230px;
  }
`;

const Notice = styled.span`
  margin-top: 5px;
  margin-left: 10px;
  color: ${({ theme }) => theme.colorNeutral500};
  ${fontStyle("14px", "22px")};
`;
const ErrorText = styled.div`
  margin-left: 135px;
  color: ${({ theme }) => theme.colorSuccess500};
`;

type Props = {
  onClose: () => void;
  currentProduct?: Product;
  warehouseCode: string;
  isB2B: boolean;
};

export default function EditProductPopup(props: Props) {
  const { onClose, currentProduct, warehouseCode, isB2B } = props;
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const { productBarcodeInfo, productStockInfo, isProductInfoFetching } = useSelector(onlineOrderState);

  const [form] = Form.useForm();
  const storageType = Form.useWatch("storageType", form);
  const effectiveDateFormValue = Form.useWatch("effectiveDate", form);
  const skuFormValue: string = Form.useWatch("sku", form);
  const storageFormValue = Form.useWatch("storageType", form);
  const canSalesQtyFormValue = Form.useWatch("canSalesQty", form);

  const [error, setError] = useState<string>("");
  const [allStockList, setAllStockList] = useState<ProductStockInfo[]>([]);
  const [stockInfoList, setStockInfoList] = useState<ProductStockInfo[]>([]);
  const [stocksForEffectiveDate, setStocksForEffectiveDate] = useState<ProductStockInfo[]>([]);
  const [sameStorageTypeStocks, setSameStorageTypeStocks] = useState<ProductStockInfo[]>([]);
  const [stocksForBatch, setStocksForBatch] = useState<ProductStockInfo[]>([]);

  const matchingStorage = storageTypeOptions.filter((option) =>
    allStockList.some((storage) => option.key === storage.storageType),
  );

  const handleSubmit = (formValue: any) => {
    if (currentProduct) {
      // 編輯
      const params = {
        orderId,
        productId: currentProduct.id,
        sku: formValue.sku,
        storageType: formValue.storageType,
        estimatedQty: Number(formValue.estimatedQty),
        unitPrice: Number(formValue.unitPrice),
        batch: formValue.batch || null,
        effectiveDate: formValue.effectiveDate ? moment(formValue.effectiveDate).format("YYYY-MM-DD") : "",
      };

      dispatch(fetchUpdateProduct(params));
    } else {
      // 新增
      const params = {
        orderId,
        sku: formValue.sku,
        storageType: formValue.storageType,
        estimatedQty: Number(formValue.estimatedQty),
        unitPrice: formValue.unitPrice ? Number(formValue.unitPrice) : null,
        batch: formValue.batch || null,
        effectiveDate: formValue.effectiveDate ? moment(formValue.effectiveDate).format("YYYY-MM-DD") : "",
      };
      dispatch(fetchCreateProduct(params));
    }
    onClose();
  };

  useEffect(() => {
    // setIsLoading(true);
    const timer = setTimeout(() => {
      if (skuFormValue && warehouseCode) {
        dispatch(fetchProductDetailBySku({ sku: skuFormValue, warehouse: warehouseCode }));
        dispatch(fetchProductStockBySku({ sku: skuFormValue, warehouse: warehouseCode }));
      }
    }, 1000);

    // 清除定时器
    return () => clearTimeout(timer);
  }, [dispatch, skuFormValue, warehouseCode]);

  useEffect(() => {
    const productInfo = productBarcodeInfo?.results[0];
    if (productInfo) {
      setError("");
      form.setFieldsValue({
        standardPrice: 0,
        barcode: productInfo.barcode,
        productName: productInfo.productName,
        weightedCost: productInfo.weightedCost,
      });
    } else {
      setError("查無對應商品，請確認商品品號是否存在");
      form.resetFields();
    }
  }, [productBarcodeInfo, form]);

  useEffect(() => {
    if (productStockInfo.length === 0) return;
    const stockInfo = productStockInfo[0]?.stockInfo;
    setStockInfoList(stockInfo);
    const stockMap = new Map();
    const stocks = stockInfo.filter((item) => {
      if (!stockMap.has(item.storageType)) {
        stockMap.set(item.storageType, true);
        return true;
      }
      return false;
    });
    setAllStockList(stocks);
  }, [productStockInfo]);

  useEffect(() => {
    if (storageFormValue) {
      const filteredStock = stockInfoList.filter((stockInfo) => {
        return stockInfo.storageType === storageFormValue;
      });
      setSameStorageTypeStocks(filteredStock);
    }
  }, [stockInfoList, storageFormValue]);

  useEffect(() => {
    const stockMap = new Map();
    const stocks = sameStorageTypeStocks.filter((item) => {
      if (!stockMap.has(item.effectiveDate)) {
        stockMap.set(item.effectiveDate, true);
        return true;
      }
      return false;
    });
    setStocksForEffectiveDate(stocks);
  }, [sameStorageTypeStocks]);

  useEffect(() => {
    if (effectiveDateFormValue) {
      const stockMap = new Map();
      const stocks = sameStorageTypeStocks.filter((item) => {
        if (!stockMap.has(item.batch)) {
          stockMap.set(item.batch, true);
          return true;
        }
        return false;
      });
      setStocksForBatch(stocks);
    }
  }, [effectiveDateFormValue, sameStorageTypeStocks]);
  const handleBatchChange = (batch: string) => {
    const filteredStockInfo = stocksForBatch.filter((item) => {
      return item.batch === batch;
    });
    form.setFieldsValue({
      stock: filteredStockInfo[0].canSalesQty,
    });
  };

  useEffect(() => {
    if (currentProduct) {
      form.setFieldsValue({
        ...currentProduct,
      });
    } else {
      form.resetFields();
    }
  }, [currentProduct, form]);

  return (
    <PopupBackground close={onClose} fixed>
      <Wrapper
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 8 }}
        labelAlign="left"
        layout="horizontal"
        colon={false}
        onFinish={handleSubmit}
        initialValues={{ standardPrice: 0 }}
      >
        <Spin spinning={isProductInfoFetching}>
          <Form.Item shouldUpdate label="品號" name="sku" labelAlign="left" rules={[{ required: true, message: "" }]}>
            <Input />
          </Form.Item>
          <ErrorText style={{ color: "#EC6922" }}>{error}</ErrorText>

          <Row>
            <Form.Item label="barcode" name="barcode" labelAlign="left" rules={[{ required: true, message: "" }]}>
              <Input disabled />
            </Form.Item>
            <Notice>*輸入品號後自動帶入</Notice>
          </Row>
          <Row>
            <Form.Item label="商品名稱" name="productName" labelAlign="left" rules={[{ required: true, message: "" }]}>
              <Input disabled />
            </Form.Item>
            <Notice>*輸入品號後自動帶入</Notice>
          </Row>
          <Row>
            <Form.Item label="成本" name="weightedCost" labelAlign="left">
              <Input disabled />
            </Form.Item>
            <Notice>*輸入品號後自動帶入</Notice>
          </Row>
          <Row>
            <Form.Item label="標準價" name="standardPrice" labelAlign="left">
              <Input disabled />
            </Form.Item>
          </Row>
          <Form.Item
            shouldUpdate
            label="倉別"
            name="storageType"
            labelAlign="left"
            rules={[{ required: true, message: "" }]}
          >
            <Select
              style={{ width: "230px" }}
              onChange={() =>
                form.setFieldsValue({
                  effectiveDate: undefined,
                  batch: undefined,
                  stock: undefined,
                })
              }
            >
              {matchingStorage.map((storage) => (
                <Select.Option value={storage.value}>
                  {storage.value}
                  {storage.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="商品效期" name="effectiveDate" labelAlign="left" rules={[{ required: true, message: "" }]}>
            <Select placeholder="請先選擇倉別" disabled={!storageType}>
              {stocksForEffectiveDate.map((stockInfo) => {
                return (
                  <Select.Option value={stockInfo.effectiveDate || "no-effectiveDate"}>
                    {stockInfo.effectiveDate ? stockInfo.effectiveDate : "無效期限制"}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label="批號" name="batch" labelAlign="left">
            <Select
              onChange={(e) => handleBatchChange(e)}
              placeholder="請先選擇效期"
              disabled={!effectiveDateFormValue}
            >
              {stocksForBatch.map((stockInfo) => {
                return (
                  <Select.Option value={stockInfo.batch}>{stockInfo.batch ? stockInfo.batch : "無批號"}</Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Row>
            <Form.Item label="庫存量" name="stock" labelAlign="left">
              <Input disabled />
            </Form.Item>
          </Row>
          <Form.Item
            label="商品數量(pcs)"
            name="estimatedQty"
            labelAlign="left"
            rules={[
              { required: true, message: "" },
              {
                validator: (rule, value) => (value > canSalesQtyFormValue ? Promise.reject() : Promise.resolve()),
                message: "商品數量超過庫存量，請修正",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item label="單品總金額" name="unitPrice" labelAlign="left" rules={[{ required: !isB2B, message: "" }]}>
            {isB2B ? "-" : <Input type="number" />}
          </Form.Item>
          <Row justify="end" align="middle">
            <Button type="default" style={{ marginRight: 10 }} onClick={onClose}>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </Row>
        </Spin>
      </Wrapper>
    </PopupBackground>
  );
}
