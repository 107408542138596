/* eslint-disable camelcase */
import CoreAPI from "./CoreAPI";
import normalizeWarehouse, {
  ContractVendors,
  Purchase,
  PurchaseLines,
  PurchaseList,
  PurchaseVpc,
} from "./utils/normalizeWarehouse";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";

export interface FetchPurchaseList {
  sku?: string;
  owner?: number;
  stoNumber?: string;
  vendor?: number;
  customsClearance?: number;
  showDestroy?: boolean;
  noStockin?: boolean;
  specDateBefore?: string;
  specDateAfter?: string;
  limit: number;
  offset: number;
}

export interface CreatePurchasePayload {
  vendor: number;
  specDate: string;
  specPeriod: number;
}

export interface UpdatePurchasePayload extends CreatePurchasePayload {
  purchaseId: number;
}

export interface FetchPurchaseLines {
  limit: number;
  page: number;
  purchaseId?: number;
  purchaseIds?: number[];
  customsClearance?: number;
  stoNumber?: string;
  sku?: string;
}

export interface FetchPurchaseVpc {
  vendor: number;
  sku: string;
}

export interface CreatePurchseLinePayload {
  purchase: number;
  requestQty: number;
  purchasePrice: number;
  vpc: number;
  rebate?: number;
  tariffRate?: number;
  processingFee?: number;
  customsClearanceFee?: number;
  expiredDate: string;
  canAcceptExpirationDate?: string | null;
}

export interface UpdatePurchseLinePayload extends CreatePurchseLinePayload {
  purchaseLineId: number;
}

export enum PurchaseListOrdering {
  STOCKIN_DATE_DESC = "-max_stockin_date",
  STOCKIN_DATE_ASC = "max_stockin_date",
  SPEC_DATE_DESC = "-spec_date",
  SPEC_DATE_ASC = "spec_date",
}

interface WarehouseAPI {
  fetchPurchaseList: (
    filterOptions: FetchPurchaseList,
  ) => Promise<{ count: number; next: string; previous: string; results: PurchaseList[] }>;
  fetchPurchase: (purchaseId: number, showDestroy: String) => Promise<Purchase>;
  fetchContractVendors: () => Promise<ContractVendors[]>;
  fetchCreatePurchase: (payload: CreatePurchasePayload) => Promise<void>;
  fetchCopyPurchase: (purchaseId: number) => void;
  fetchDestroyPurchase: (purchaseId: number) => void;
  fetchUpdatePurchase: (payload: UpdatePurchasePayload) => Promise<void>;
  fetchPurchaseLines: (
    filterOptions: FetchPurchaseLines,
  ) => Promise<{
    count: number;
    next: string;
    previous: string;
    results: { abnormalCost: boolean; data: PurchaseLines[] };
  }>;
  fetchPurchaseVpc: (filterOptions: FetchPurchaseVpc) => Promise<PurchaseVpc>;
  fetchCreatePurchseLine: (payload: CreatePurchseLinePayload) => Promise<void>;
  fetchPurchaseLine: (purchaseLineId: number) => Promise<PurchaseLines>;
  fetchUpdatePurchaseLine: (payload: UpdatePurchseLinePayload) => Promise<void>;
  fetchDeletePurchaseLine: (purchaseLineId: number) => Promise<void>;
  fetchDeployToLMS: (purchaseId: number) => Promise<void>;
  fetchSubmitSettlement: (purchaseId: number, warehouseCode: string) => Promise<void>; // 提交結算(上架完成)
}

const apiClient = new CoreAPI();

const WarehouseApi: WarehouseAPI = {
  fetchPurchaseList: async (filterOptions) => {
    const getParams = {
      ...transformCamelToSnake(filterOptions),
      show_destroy: Number(filterOptions.showDestroy),
      no_stockin: Number(filterOptions.noStockin),
    };
    const response = await apiClient.getData("/manage/purchase/purchases/", getParams);

    return {
      count: response.data.result.count,
      next: response.data.result.next,
      previous: response.data.result.previous,
      results: transformAPIKeyToCamel(response.data.result.results),
    };
  },
  fetchPurchase: async (purchaseId, showDestroy) => {
    const response = await apiClient.getData(`/manage/purchase/purchases/${purchaseId}/`, {
      show_destroy: showDestroy,
    });
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchContractVendors: async () => {
    const response = await apiClient.getData("/manage/vendor/vendor-product-contracts/vendors/", {});
    return normalizeWarehouse.contractVendors(response.data.result);
  },
  fetchCreatePurchase: async (payload) => {
    await apiClient.postData("/manage/purchase/purchases/", {
      vendor: payload.vendor,
      spec_date: payload.specDate,
      spec_period: payload.specPeriod,
    });
  },
  fetchCopyPurchase: async (purchaseId) => {
    await apiClient.postData(`/manage/purchase/purchases/${purchaseId}/copy/?show_destroy=1`, {});
  },
  fetchDestroyPurchase: async (purchaseId) => {
    await apiClient.putData(`/manage/purchase/purchases/${purchaseId}/destroy/`, {});
  },
  fetchUpdatePurchase: async (payload) => {
    await apiClient.patchData(`/manage/purchase/purchases/${payload.purchaseId}/`, {
      vendor: payload.vendor,
      spec_date: payload.specDate,
      spec_period: payload.specPeriod,
    });
  },
  fetchPurchaseLines: async (filterOptions) => {
    const response = await apiClient.getData("/manage/purchase/purchase-lines/", {
      sku: filterOptions.sku,
      purchase: filterOptions.purchaseId,
      purchase_ids: filterOptions.purchaseIds?.toString(),
      sto_number: filterOptions.stoNumber,
      customs_clearance: filterOptions.customsClearance,
      limit: filterOptions.limit,
      offset: (filterOptions.page - 1) * filterOptions.limit,
    });
    return {
      count: response.data.result.count,
      next: response.data.result.next,
      previous: response.data.result.previous,
      results: {
        abnormalCost: response.data.result.results.abnormal_cost,
        data: normalizeWarehouse.purchaseLines(response.data.result.results.data),
      },
    };
  },
  fetchPurchaseVpc: async (filterOptions) => {
    const response = await apiClient.getData("/manage/vendor/vendor-product-contracts/purchase-vpc/", {
      vendor: filterOptions.vendor,
      sku: filterOptions.sku,
    });
    return normalizeWarehouse.purchaseVpc(response.data.result);
  },

  fetchCreatePurchseLine: async (payload) => {
    await apiClient.postData("/manage/purchase/purchase-lines/", {
      purchase: payload.purchase,
      purchase_price: payload.purchasePrice,
      request_qty: payload.requestQty,
      vpc: payload.vpc,
      expired_date: payload.expiredDate,
      can_accept_expiration_date: payload.canAcceptExpirationDate,
    });
  },
  fetchPurchaseLine: async (purchaseLineId) => {
    const response = await apiClient.getData(`/manage/purchase/purchase-lines/${purchaseLineId}/`, {});
    return normalizeWarehouse.purchaseLine(response.data.result);
  },
  fetchUpdatePurchaseLine: async (payload) => {
    await apiClient.patchData(`/manage/purchase/purchase-lines/${payload.purchaseLineId}/`, {
      purchase: payload.purchase,
      purchase_price: payload.purchasePrice,
      request_qty: payload.requestQty,
      vpc: payload.vpc,
      expired_date: payload.expiredDate,
      can_accept_expiration_date: payload.canAcceptExpirationDate,
    });
  },
  fetchDeletePurchaseLine: async (purchaseLineId) => {
    await apiClient.deleteData(`/manage/purchase/purchase-lines/${purchaseLineId}/`, {});
  },
  fetchDeployToLMS: async (purchaseId) => {
    await apiClient.putData(`/manage/purchase/purchases/${purchaseId}/deploy-to-lms/`, {});
  },
  fetchSubmitSettlement: async (purchaseId, warehouseCode) => {
    const payload = transformCamelToSnake({ purchaseId, warehouseCode });
    await apiClient.postData("/manage/erp/pu/asn/settlement/", payload);
  },
};

export default WarehouseApi;
