/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import ImportFileModal from "@component/ImportFileModal";
import { BatchUpdateOrderLines, convertCurrencyType, OrderLinesList } from "@api/throughShipment/throughShipmentApi";
import useDebounce from "@hooks/useDebounce";
import { SearchOutlined } from "@ant-design/icons";
import {
  externalOrderState,
  fetchOrderLines,
  fetchCreateProduct,
  importIWappsProductsXLS,
  fetchDeleteProduct,
  setImportFilePopup,
  fetchBatchUpdateOrderLines,
  fetchSingleUpdateOrderLines,
} from "@redux/externalOrderSlice";
import { Button, Input, Space, Table, Select, Form, Modal, message } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import storageTypeOptions from "@constant/StorageType";
import {
  BOStockState,
  fetchBOStockList,
  resetBOStockList,
  updateBOStockParams,
} from "@redux/brandOperation/BOstockSlice";
import brandOperationApi, { BOStockItemDetail } from "@api/brandOperationApi";
import { SelectValue } from "antd/lib/select";
import type { ColumnsType } from "antd/es/table";
import { FilterDropdownProps } from "antd/lib/table/interface";
import CheckOrderList from "./CheckOrderList";

const { TextArea } = Input;

const IconWrapper = styled.div`
  position: absolute;
  top: 28px;
`;
const Div = styled.div`
  position: relative;
`;

const TextAreaWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const Wrapper = styled.div`
  padding: 23px 16px;
  border: 1px solid #f0f0f0;
`;

const CustomInput = styled(Input)<{ width?: string }>`
  width: ${({ width }) => width || "100px"};
  &.error {
    border: ${({ theme }) => `1px solid ${theme.colorSecondary500}`};
  }
`;
const InputHelpText = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colorSecondary500};
`;
const Footer = styled.div`
  position: fixed;
  right: 25px;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 13px 12px;
  align-items: center;
  justify-content: flex-end;
  background-color: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
  z-index: 10;
`;
const CustomButton = styled(Button)<{ marginBottom?: string; marginRight?: string; width?: string }>`
  margin-right: ${({ marginRight }) => marginRight || ""};
  margin-bottom: ${({ marginBottom }) => marginBottom || ""};
  width: ${({ width }) => width || ""};
`;
const CustomSpace = styled(Space)`
  width: 100%;

  .ant-space-item {
    width: 50%;
  }
`;

const CustomSelect = styled(Select)`
  width: 100%;
  margin-bottom: 10px;
`;
const WarnSelect = styled(Select)`
  width: 100%;
  border: red solid 1px;
  border-radius: 2px;
`;

type Props = {
  onNextStep: () => void;
  onBackStep: () => void;
};

export default function ChooseOrderList(props: Props) {
  const { onNextStep, onBackStep } = props;

  const dispatch = useDispatch();
  const {
    orderLinesFilter,
    orderLinesList,
    showImportFilePopup,
    externalOrderInfo,
    isFetching,
    isEditOrderListSuccess,
    showErrorResult,
  } = useSelector(externalOrderState);

  const { externalOrderId } = useParams();
  const [form] = Form.useForm();
  const [formForAdd] = Form.useForm();

  const [checkOrderListPopup, setCheckOrderListPopup] = useState<boolean>(false);
  const [orderLinesMap, setOrderLinesMap] = useState<Map<number, OrderLinesList>>(new Map());
  const [sortOrderLines, setSortOrderLines] = useState<any[]>([]);
  const [addProductPopup, setAddProductPopup] = useState(false);

  const { isFetching: isBOListFetching, BOstockListParams, BOStockList } = useSelector(BOStockState);

  const handleSearch = (selectedKeys: string[], confirm: (param?: any) => void, dataIndex: any) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    form.resetFields(["selectType"]);
  };

  const getColumnSearchProps = (dataIndex: string, type?: "storageType") => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
      <div style={{ padding: "20px" }}>
        {type === "storageType" ? (
          <Form.Item name="selectType" noStyle>
            <CustomSelect onChange={(e) => setSelectedKeys(`${e}` ? [`${e}`] : [])}>
              {storageTypeOptions.map((option) => {
                return (
                  <Select.Option key={option.key} value={option.value}>
                    {option.name}
                  </Select.Option>
                );
              })}
            </CustomSelect>
          </Form.Item>
        ) : (
          <Input
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            style={{ marginBottom: 8, display: "block" }}
          />
        )}

        <CustomSpace>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: "100%", height: 32 }}
          >
            搜尋
          </Button>
          <Button
            size="small"
            style={{ width: "100%", height: 32 }}
            onClick={() => clearFilters && handleReset(clearFilters)}
          >
            重設
          </Button>
        </CustomSpace>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined, fontSize: "15px" }} />
    ),
    onFilter: (value: any, record: any) => {
      return record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes((value as string)?.toLowerCase());
    },
  });

  const handleSortOrderLines = (sort: "desc" | "asc", field: keyof OrderLinesList) => {
    if (sort === "desc") {
      return [...orderLinesList.results].sort((a, b) => Number(a[field]) - Number(b[field]));
    }
    return [...orderLinesList.results].sort((a, b) => Number(b[field]) - Number(a[field]));
  };

  const onHandleOrderLinesMap = (values: OrderLinesList) => {
    const map = new Map(orderLinesMap);
    map.set(values.id, values);
    setOrderLinesMap(map);

    const orderLinesListForm = Array.from(map.values()).filter(
      (item) => item.effectiveDateBatch || item.canAcceptDays || (item.quotedPriceTwd && item.notes),
    );

    const result: BatchUpdateOrderLines[] = orderLinesListForm.map((item) => {
      return {
        id: item.id,
        batchNumber: item.batchNumber || "",
        effectiveDate: item.effectiveDate || null,
        canAcceptDays: item.canAcceptDays || undefined,
        cost: Number(item.cost),
        quotedPriceCurrency: Number(item.quotedPriceCurrency),
        quotedPriceTwd: Number(item.quotedPriceTwd),
        pcsPerCarton: item.pcsPerCarton,
        orderedQty: item.orderedQty,
        notes: item.notes,
        storageType: item.storageType,
      };
    });
    debouncedDispatch(result);
  };

  const debouncedDispatch = useDebounce((result: BatchUpdateOrderLines[]) => {
    dispatch(fetchSingleUpdateOrderLines(result));
  }, 500);

  const onFetchEffectiveDateBatch = (e: SelectValue, values: OrderLinesList) => {
    onHandleOrderLinesMap({
      ...orderLinesMap.get(values.id)!,
      storageType: (e as unknown) as string,
      effectiveDateBatch: "",
      showData: false,
    });
    form.setFieldsValue({
      [`storageType-${values.id}`]: e,
      [`stock-${values.id}`]: 0,
    });
  };

  const onFetchDropdown = (values: OrderLinesList) => {
    const params = {
      ...BOstockListParams,
      no: values.no,
      storageType: form.getFieldValue([`storageType-${values.id}`]) || values.storageType,
      effectiveDate: undefined,
      batch: undefined,
    };
    dispatch(resetBOStockList());
    dispatch(updateBOStockParams(params));
    dispatch(fetchBOStockList());
  };

  const onFetchStock = async (e: SelectValue, values: OrderLinesList) => {
    try {
      const [effectiveDate, batch] = JSON.parse(e as string);
      const params = {
        ...BOstockListParams,
        no: values.no,
        storageType: form.getFieldValue([`storageType-${values.id}`]) || values.storageType,
        effectiveDate,
        batch,
      };
      dispatch(updateBOStockParams(params));
      const response = await brandOperationApi.fetchBOStockList(params);

      form.setFieldsValue({
        [`stock-${values.id}`]: response.totalStock,
      });
      onHandleOrderLinesMap({
        ...orderLinesMap.get(values.id)!,
        effectiveDateBatch: (e as unknown) as string,
        effectiveDate,
        batchNumber: batch,
        showData: true,
        canSalesQty: response.totalStock,
      });
      form.setFieldsValue({
        [`effectiveDate_batch-${values.id}`]: e,
      });
    } catch (error: any) {
      message.error(error);
    }
  };

  const handelOnSubmit = () => {
    setCheckOrderListPopup(true);
  };

  const columns: ColumnsType<OrderLinesList> = [
    {
      key: "id",
      dataIndex: "id",
      title: "編號",
      width: 80,
      fixed: "left",
    },
    {
      key: "no",
      dataIndex: "no",
      title: <Div>商品品號</Div>,
      width: 150,
      fixed: "left",
      ...getColumnSearchProps("no"),
    },
    {
      key: "sku",
      dataIndex: "sku",
      title: "SKU",
      width: 150,
      ...getColumnSearchProps("sku"),
    },
    {
      key: "name",
      dataIndex: "name",
      title: "品名",
      width: 300,
      ...getColumnSearchProps("name"),
    },
    {
      key: "storageType",
      dataIndex: "storageType",
      title: "倉別",
      width: 120,
      ...getColumnSearchProps("storageType", "storageType"),
      render: (data, values) => {
        return (
          <>
            <CustomSelect
              value={orderLinesMap.get(values.id)?.storageType}
              onChange={(e) => onFetchEffectiveDateBatch(e as SelectValue, values)}
            >
              {storageTypeOptions.map((option) => {
                return (
                  <Select.Option key={option.key} value={option.value}>
                    {option.name}
                  </Select.Option>
                );
              })}
            </CustomSelect>
            <Form.Item noStyle name={`storageType-${values.id}`} />
          </>
        );
      },
    },
    {
      key: "effectiveDate_batch",
      dataIndex: "effectiveDate_batch",
      title: "效期＿批號",
      width: 220,
      ...getColumnSearchProps("effectiveDateBatch"),
      render: (_, values) => {
        return (
          <>
            {orderLinesMap.get(values.id)?.showData ? (
              <CustomSelect
                value={orderLinesMap.get(values.id)?.effectiveDateBatch || undefined}
                onChange={(e) => onFetchStock(e as SelectValue, values)}
                onFocus={(e) => onFetchDropdown(values)}
                loading={isBOListFetching}
              >
                {BOStockList?.results[0]?.details.map((option: BOStockItemDetail) => {
                  const value = JSON.stringify([option.effectiveDate || "", option.batch || ""]);
                  return (
                    <Select.Option key={option.id} value={value}>
                      {`${option.effectiveDate || ""}_${option.batch || ""}`}
                    </Select.Option>
                  );
                })}
              </CustomSelect>
            ) : (
              <WarnSelect
                onChange={(e) => onFetchStock(e as SelectValue, values)}
                onFocus={(e) => onFetchDropdown(values)}
                loading={isBOListFetching}
              >
                {BOStockList?.results[0]?.details.map((option: BOStockItemDetail) => {
                  const value = JSON.stringify([option.effectiveDate || "", option.batch || ""]);
                  return (
                    <Select.Option key={option.id} value={value}>
                      {`${option.effectiveDate || ""}_${option.batch || ""}`}
                    </Select.Option>
                  );
                })}
              </WarnSelect>
            )}
            <Form.Item noStyle name={`effectiveDate_batch-${values.id}`} />
          </>
        );
      },
    },
    {
      key: "canAcceptDays",
      dataIndex: "canAcceptDays",
      title: "允收天數",
      width: 150,
      render: (_, values) => (
        <CustomInput
          value={orderLinesMap.get(values.id)?.canAcceptDays || undefined}
          onChange={(e) =>
            onHandleOrderLinesMap({
              ...orderLinesMap.get(values.id)!,
              canAcceptDays: (e.target.value as unknown) as number,
            })
          }
        />
      ),
    },

    {
      key: "cost",
      dataIndex: "cost",
      title: "商品成本(TWD)",
      width: 150,
      sorter: () => 0,
      onHeaderCell: (column: any) => {
        return {
          onClick: () => {
            if (column.title.props.title === "Click to sort descending") {
              setSortOrderLines(handleSortOrderLines("desc", "cost"));
            } else if (column.title.props.title === "Click to sort ascending") {
              setSortOrderLines(handleSortOrderLines("asc", "cost"));
            }
          },
        };
      },
      render: (_, values) => (
        <CustomInput
          value={orderLinesMap.get(values.id)?.cost}
          onChange={(e) =>
            onHandleOrderLinesMap({
              ...orderLinesMap.get(values.id)!,
              cost: (e.target.value as unknown) as number,
            })
          }
          disabled
        />
      ),
    },
    {
      key: "quotedPriceCurrency",
      dataIndex: "quotedPriceCurrency",
      title: `商品報價(${convertCurrencyType(externalOrderInfo!.currency)})`,
      width: 150,
      sorter: () => 0,
      onHeaderCell: (column: any) => {
        return {
          onClick: () => {
            if (column.title.props.title === "Click to sort descending") {
              setSortOrderLines(handleSortOrderLines("desc", "quotedPriceCurrency"));
            } else if (column.title.props.title === "Click to sort ascending") {
              setSortOrderLines(handleSortOrderLines("asc", "quotedPriceCurrency"));
            }
          },
        };
      },
      render: (_, values) => (
        <CustomInput
          value={orderLinesMap.get(values.id)?.quotedPriceCurrency}
          onChange={(e) =>
            onHandleOrderLinesMap({
              ...orderLinesMap.get(values.id)!,
              quotedPriceCurrency: (e.target.value as unknown) as number,
            })
          }
        />
      ),
    },
    {
      key: "quotedPriceTwd",
      dataIndex: "quotedPriceTwd",
      title: "商品報價(TWD)",
      width: 150,
      sorter: () => 0,
      onHeaderCell: (column: any) => {
        return {
          onClick: () => {
            if (column.title.props.title === "Click to sort descending") {
              setSortOrderLines(handleSortOrderLines("desc", "quotedPriceTwd"));
            } else if (column.title.props.title === "Click to sort ascending") {
              setSortOrderLines(handleSortOrderLines("asc", "quotedPriceTwd"));
            }
          },
        };
      },
      render: (_, values) => (
        <CustomInput
          value={orderLinesMap.get(values.id)?.quotedPriceTwd}
          onChange={(e) =>
            onHandleOrderLinesMap({
              ...orderLinesMap.get(values.id)!,
              quotedPriceTwd: (e.target.value as unknown) as number,
            })
          }
        />
      ),
    },
    {
      key: "canSalesQty",
      dataIndex: "canSalesQty",
      title: "庫存數",
      width: 100,
      render: (data, values) => {
        return <Form.Item shouldUpdate>{data}</Form.Item>;
      },
    },
    {
      key: "notes",
      dataIndex: "notes",
      title: "備註",
      width: 200,
      render: (_, values) => {
        return (
          <TextAreaWrapper>
            <TextArea
              value={orderLinesMap.get(values.id)?.notes}
              autoSize={{ minRows: 2, maxRows: 2 }}
              onChange={(e) =>
                onHandleOrderLinesMap({
                  ...orderLinesMap.get(values.id)!,
                  notes: (e.target.value as unknown) as string,
                })
              }
            />
          </TextAreaWrapper>
        );
      },
    },
    {
      key: "orderedQty",
      dataIndex: "orderedQty",
      title: "總數量(pcs)",
      width: 110,
      fixed: "right",
      render: (_, values) => {
        const currentItem = orderLinesMap.get(values.id);
        return (
          <>
            <CustomInput
              width="80"
              className={values?.validErrorMessage ? "error" : ""}
              value={currentItem?.orderedQty}
              onChange={(e) => {
                const { value } = e.target;
                const orderedQtyNumber = value && !Number.isNaN(Number(value)) ? Number(value) : 0;
                if (currentItem && orderedQtyNumber > currentItem.canSalesQty) {
                  onHandleOrderLinesMap({
                    ...currentItem!,
                    orderedQty: orderedQtyNumber,
                    validErrorMessage: "超過可賣量庫存數",
                  });
                  return;
                }
                onHandleOrderLinesMap({
                  ...orderLinesMap.get(values.id)!,
                  orderedQty: orderedQtyNumber,
                  validErrorMessage: "",
                });
              }}
            />
            {currentItem && <InputHelpText>{currentItem.validErrorMessage}</InputHelpText>}
          </>
        );
      },
    },
    {
      key: "",
      dataIndex: "delete",
      title: () => "",
      fixed: "right",
      width: 50,
      render: (_, values) => {
        return (
          <Button
            type="link"
            onClick={() => dispatch(fetchDeleteProduct({ externalOrder: externalOrderId, id: values.id }))}
          >
            刪除
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    if (externalOrderId) {
      dispatch(fetchOrderLines({ ...orderLinesFilter, externalOrder: parseInt(externalOrderId, 10), limit: 300 }));
    }
  }, [dispatch, orderLinesFilter, externalOrderId]);

  useEffect(() => {
    const map = new Map();
    orderLinesList.results.forEach((orderLine) => {
      const newMap = {
        ...orderLine,
        effectiveDateBatch: orderLine.effectiveDate && `${orderLine.effectiveDate}_${orderLine.batchNumber}`,
        showData: true,
        validErrorMessage: "",
      };

      map.set(orderLine.id, newMap);
    });
    setOrderLinesMap(map);
  }, [orderLinesList]);

  useEffect(() => {
    setSortOrderLines(orderLinesList.results);
  }, [orderLinesList]);

  useEffect(() => {
    if (isEditOrderListSuccess) {
      onNextStep();
    }
  }, [isEditOrderListSuccess, onNextStep]);

  useEffect(() => {
    setSortOrderLines(Array.from(orderLinesMap.values()));
  }, [orderLinesMap]);

  const handleCreateProduct = () => {
    const formValues: { productNo: string } = formForAdd.getFieldsValue();
    dispatch(fetchCreateProduct({ externalOrder: externalOrderId, no: formValues.productNo }));
    setAddProductPopup(!addProductPopup);

    formForAdd.resetFields();
  };

  const handelAddXLS = (file: File) => {
    dispatch(importIWappsProductsXLS({ externalOrder: externalOrderId, file }));
  };

  useEffect(() => {
    if (showErrorResult.length !== 0) {
      Modal.error({
        title: "請檢查以下資料",
        content: (
          <>
            {showErrorResult.map((result) => (
              <p key={result}>{result}</p>
            ))}
          </>
        ),
      });
    }
  }, [showErrorResult]);

  return (
    <Wrapper>
      <CustomButton marginBottom="15px" marginRight="12px" onClick={() => setAddProductPopup(!addProductPopup)}>
        新增商品
      </CustomButton>
      <CustomButton
        type="primary"
        marginBottom="15px"
        onClick={() => dispatch(setImportFilePopup(!showImportFilePopup))}
      >
        匯入商品
      </CustomButton>
      <Form colon={false} labelAlign="left" form={form} initialValues={{}}>
        <Table
          loading={isFetching}
          columns={columns}
          dataSource={sortOrderLines}
          pagination={false}
          scroll={{ x: "max-content", y: 400 }}
          rowKey="id"
        />
      </Form>
      <Footer>
        <CustomButton marginRight="12px" onClick={onBackStep}>
          返回
        </CustomButton>
        <CustomButton
          type="primary"
          width="100px"
          disabled={Array.from(orderLinesMap.values()).some((item) => {
            return (
              item.canAcceptDays &&
              moment(item.effectiveDate).subtract(Number(item.canAcceptDays), "days").isBefore(moment())
            );
          })}
          onClick={() => handelOnSubmit()}
        >
          儲存
        </CustomButton>
      </Footer>
      {checkOrderListPopup && (
        <CheckOrderList
          orderLinesList={Array.from(orderLinesMap.values()).filter(
            (item) => item.quotedPriceTwd && item.quotedPriceCurrency && item.pcsPerCarton && item.orderedQty,
          )}
          onClose={() => setCheckOrderListPopup(false)}
        />
      )}
      {addProductPopup && (
        <Modal
          visible={addProductPopup}
          confirmLoading={isFetching}
          onOk={handleCreateProduct}
          onCancel={() => setAddProductPopup(!addProductPopup)}
          okText="確認"
          cancelText="取消"
          maskClosable={false}
        >
          <Form
            colon={false}
            wrapperCol={{ span: 12 }}
            labelAlign="left"
            form={formForAdd}
            initialValues={{ productNo: "" }}
          >
            <Form.Item label="商品品號" name="productNo" rules={[{ required: true, message: "" }]}>
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}
      {showImportFilePopup && (
        <ImportFileModal
          csvErrorMessage=""
          closePopup={() => dispatch(setImportFilePopup(false))}
          clearErrorMessage={() => {}}
          handleUploadFile={handelAddXLS}
          templatePath="admin/template/xls/external_orderlines_template.xlsx"
          fileType=".xlsx,.csv,.xls"
        />
      )}
    </Wrapper>
  );
}
